import React from 'react';
import { Heading } from '../../components';
import { matchTranslate, richText } from '../../util/richText';
import { useIntl } from 'react-intl';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextMaybe = props => {
  const { text, heading, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;

  const intl = useIntl();

  return text ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {matchTranslate(`{{${heading}}}`, intl)}
        </Heading>
      ) : null}
      <p className={textClass}>
        {richText(text, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
          longWordClass: css.longWord,
        })}
      </p>
    </div>
  ) : null;
};

export default SectionTextMaybe;
