import React, { useCallback } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { H2, LoadMore, Reviews } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReviews } from './ListingPage.duck';

import css from './ListingPage.module.css';

const LOAD_MORE_PER_PAGE = 10;

const SectionReviews = props => {
  const { reviews, fetchReviewsError, fetchReviewsInProgress } = props;

  const dispatch = useDispatch();

  const { id: listingId, reviewsPagination } = useSelector(state => state.ListingPage);

  const loadMoreReviews = useCallback(
    nextPage => {
      let nextPageFinal = nextPage;

      // This case should only happen on the first page,
      // so we can fill up the remaining reviews up to LOAD_MORE_PER_PAGE
      if (reviews?.length < LOAD_MORE_PER_PAGE) {
        nextPageFinal = reviewsPagination.page;
      }

      dispatch(fetchReviews(listingId, { page: nextPageFinal, perPage: LOAD_MORE_PER_PAGE }));
    },
    [dispatch, listingId, reviews, reviewsPagination]
  );

  return (
    <div>
      {fetchReviewsError ? (
        <H2 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H2>
      ) : null}
      <Reviews
        reviews={reviews}
        loadMoreNode={
          <LoadMore
            pagination={reviewsPagination}
            inProgress={fetchReviewsInProgress}
            loadMoreFn={loadMoreReviews}
            className={css.loadMore}
          />
        }
      />
    </div>
  );
};

export default SectionReviews;
