import React, { useCallback, useEffect } from 'react';
import camelCase from 'lodash/camelCase';
import { useIntl } from 'react-intl';
import useInterfaceLang from '../../../../../hooks/useInterfaceLang';
import { toCapitalizedWords } from '../../../../../util/string';
import classNames from 'classnames';
import {
  DIMR_FIELD_SORT_ORDER,
  dimrPrefix,
  getDIMRFieldsWithResolvedValues,
  getDIMRUnitFromUnitString,
} from '../../../../../util/listing';
import { Field } from 'react-final-form';

import css from './EditListingDetailsForm.module.css';

const DIMRAttribute = props => {
  const { onUnmount, children, className } = props;

  useEffect(() => {
    return () => {
      onUnmount?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={classNames(css.dimrField, className)}>{children}</div>;
};

export const DIMRAttributes = props => {
  const {
    className,
    dimrAttributes,
    dimrKeysWithResolvedValues,
    onFieldUnmount,
    isFormField,
  } = props;

  const intl = useIntl();

  const classes = className || classNames(css.root, css.formMargins, css.dimrFields);

  return (
    <ul className={classes}>
      {dimrAttributes
        .sort(({ key: key1 }, { key: key2 }) => {
          const orderKey1 = DIMR_FIELD_SORT_ORDER[key1] || 0;
          const orderKey2 = DIMR_FIELD_SORT_ORDER[key2] || 0;

          return orderKey2 - orderKey1;
        })
        .map(field => {
          const { key, value } = field;

          const keyFinal = dimrPrefix(key);

          const isUnitValue = typeof value?.unit === 'string';

          const label = intl.formatMessage({
            id: `General.${camelCase(keyFinal)}`,
            defaultMessage: toCapitalizedWords(key),
          });

          const labelFinal = isUnitValue
            ? `${label} (${getDIMRUnitFromUnitString(value.unit)})`
            : label;

          return (
            <li key={`${dimrKeysWithResolvedValues?.articleNumber || '-'}` + keyFinal}>
              <DIMRAttribute onUnmount={() => onFieldUnmount?.(keyFinal)}>
                <div>{labelFinal}</div>
                <span>{dimrKeysWithResolvedValues?.[keyFinal] || '-'}</span>
              </DIMRAttribute>
              {isFormField && (
                <Field type="hidden" name={keyFinal}>
                  {fieldRenderProps => <input {...fieldRenderProps?.input} />}
                </Field>
              )}
            </li>
          );
        })}
    </ul>
  );
};

const ArticleAttributeFields = props => {
  const { fields, formApi } = props;

  const { interfaceLang } = useInterfaceLang();

  const intl = useIntl();

  const onFieldUnmount = useCallback(
    fieldName => {
      // When changing article, remove irrelevant fields.
      // null value so we remove it from publicData as well
      formApi.change(fieldName, null);
    },
    [formApi]
  );

  useEffect(() => {
    if (!fields?.length) return;

    formApi.batch(() => {
      const dimrFields = getDIMRFieldsWithResolvedValues(intl, fields, interfaceLang);

      dimrFields.forEach(field => {
        const { key, value } = field;

        formApi.change(key, value);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, interfaceLang]);

  const { values: formValues } = formApi?.getState() || {};

  if (!fields?.length || !formValues?.articleNumber)
    return formValues?.articleNumber ? (
      <div className={classNames(css.formMargins, css.error)}>
        {intl.formatMessage({ id: 'General.noPartFound' })}
      </div>
    ) : null;

  return (
    <DIMRAttributes
      dimrAttributes={fields}
      dimrKeysWithResolvedValues={formValues}
      onFieldUnmount={onFieldUnmount}
      isFormField
    />
  );
};

export default ArticleAttributeFields;
